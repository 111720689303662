import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly1F2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/1F/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/1F/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/1F/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/1F/4.webp`;
  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Magical Shapes
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
Class: 1F          Date: 3, 4 and 10 March

        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
    “With the softest hand, you can shape the hardest rocks!”


<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 1F presented their assembly on the topic, ‘Magical Shapes,’ on Monday, 3 March,
Tuesday, 4 March, and Monday, 10 March 2025. The assembly began with an introduction to the topic in
the form of a conversation, where students explained the meaning and significance of shapes. This was
followed by a profound thought on magical shapes.
        <br></br>
        The assembly comprised of an informative walk and talk about shapes, where students explored various
        shapes and their applications, followed by a dance performance.
<br></br>
To conclude, students emphasised how shapes play a crucial role in appraising the creativity in every child.
<br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
  “Shapes act as visual language that can convey feelings like power, creativity and unity.”
<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly1F2024;